var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar-list"},[(_vm.showLanguageSelector)?_c('language-selector',{ref:"lang",attrs:{"label":""}}):_vm._e(),_vm._l((_vm.sidebarList),function(item){return _c('div',{key:item.id,staticClass:"sidebar-item-wrapper",class:{
      'collapsed-sidebar-item': _vm.collapsed,
      'selected': _vm.isItemSelected(item.name),
      'left-border': _vm.isItemSelected(item.name) && (!item.showChildren || _vm.collapsed)
    },attrs:{"id":'sidebar-item-wrapper-' + item.id}},[_c('div',{staticClass:"sidebar-item",class:{
        'selected': _vm.isItemSelected(item.name)
      },on:{"click":function($event){return _vm.selectMenuItem(item)}}},[_c('div',{staticClass:"sidebar-logo"},[_c('icon-base',{attrs:{"width":"100%","height":"100%","iconName":item.icon,"fill":_vm.isItemSelected(item.name) ? 'white' : '#9CA6BA'}})],1),(!_vm.collapsed)?_c('div',{staticClass:"sidebar-name",class:{
          selected: _vm.isItemSelected(item.name)
        }},[_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")]):_vm._e(),(!_vm.collapsed)?_c('div',{staticClass:"sidebar-dropdown-arrow"},[(item.children.length)?_c('icon-base',{class:{
            'rotate-down': item.showChildren,
            'rotate-up': !item.showChildren
          },attrs:{"width":"100%","height":"14","iconName":"angle-down"}}):_vm._e()],1):_vm._e()]),(_vm.collapsed)?_c('div',[_c('b-tooltip',{attrs:{"target":'sidebar-item-wrapper-' + item.id,"triggers":"hover","placement":"right","custom-class":"custom-tooltip","boundary":"scrollParent","boundary-padding":"0"}},[_c('div',{staticClass:"sidebar-item sidebar-name-inside-tooltip"},[_c('div',{staticClass:"sidebar-name",class:{
              selected: _vm.isItemSelected(item.name)
            }},[_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")])]),(item.children.length)?_c('sidebar',{attrs:{"hovered":true,"sidebarList":item.children,"collapsed":false,"hideLanguageSelector":true,"sidebarListToSelect":_vm.sidebarListToSelect},on:{"updateSelectedItemName":_vm.updateSelectedItemName}}):_vm._e()],1)],1):_c('div',{staticClass:"nested-sidebar"},[_c('transition',{attrs:{"name":"nested-sidebar","duration":{ enter: 500, leave: 600 }}},[(item.showChildren)?_c('div',[_c('sidebar',{attrs:{"sidebarList":item.children,"collapsed":_vm.collapsed,"hideLanguageSelector":true,"sidebarListToSelect":_vm.sidebarListToSelect},on:{"updateSelectedItemName":_vm.updateSelectedItemName}})],1):_vm._e()])],1)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }