<template>
  <div class="sidebar-list">
    <language-selector
      v-if="showLanguageSelector"
      ref="lang"
      label=""
    ></language-selector>
    <div
      v-for="item in sidebarList"
      :key="item.id"
      class="sidebar-item-wrapper"
      :id="'sidebar-item-wrapper-' + item.id"
      :class="{
        'collapsed-sidebar-item': collapsed,
        'selected': isItemSelected(item.name),
        'left-border': isItemSelected(item.name) && (!item.showChildren || collapsed)
      }"
    >
      <div
        class="sidebar-item"
        :class="{
          'selected': isItemSelected(item.name)
        }"
        @click="selectMenuItem(item)"
      >
        <div class="sidebar-logo">
          <icon-base
            width="100%"
            height="100%"
            :iconName="item.icon"
            :fill="isItemSelected(item.name) ? 'white' : '#9CA6BA'"
          />
        </div>
        <div class="sidebar-name" v-if="!collapsed"
          :class="{
            selected: isItemSelected(item.name)
          }"
        >
          {{ $t(item.name) }}
        </div>
        <div class="sidebar-dropdown-arrow" v-if="!collapsed">
          <icon-base
            width="100%"
            height="14"
            iconName="angle-down"
            v-if="item.children.length"
            :class="{
              'rotate-down': item.showChildren,
              'rotate-up': !item.showChildren
            }"
          />
        </div>
      </div>
      <!-- Sidebars when hovered and is collapsed-->
      <div v-if="collapsed">
        <b-tooltip
         :target="'sidebar-item-wrapper-' + item.id"
          triggers="hover"
          placement="right"
          custom-class="custom-tooltip"
          boundary="scrollParent"
          boundary-padding = 0
        >
          <div class="sidebar-item sidebar-name-inside-tooltip">
            <div
              class="sidebar-name"
              :class="{
                selected: isItemSelected(item.name)
              }"
            >
              {{ $t(item.name) }}
            </div>
          </div>
          <sidebar
            v-if = "item.children.length"
            :hovered="true"
            :sidebarList="item.children"
            :collapsed="false"
            :hideLanguageSelector="true"
            :sidebarListToSelect="sidebarListToSelect"
            @updateSelectedItemName="updateSelectedItemName"
          >
          </sidebar>
        </b-tooltip>
      </div>
      <!-- Normal nested sidebar when clicked -->
      <div v-else class="nested-sidebar">
        <transition name="nested-sidebar" :duration="{ enter: 500, leave: 600 }"
        >
          <div v-if="item.showChildren">
            <sidebar
              :sidebarList="item.children"
              :collapsed="collapsed"
              :hideLanguageSelector="true"
              :sidebarListToSelect="sidebarListToSelect"
              @updateSelectedItemName="updateSelectedItemName"
            >
            </sidebar>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from './IconBase.vue'
import LanguageSelector from './LanguageSelector'
import { mapActions } from 'vuex'
import { BTooltip } from 'bootstrap-vue'

export default {
  name: 'Sidebar',
  props: {
    sidebarList: Array,
    sidebarListToSelect: Array,
    collapsed: {
      type: Boolean,
      default: false
    },
    hideLanguageSelector: {
      type: Boolean,
      default: false
    },
    nestedSidebar: {
      type: Boolean,
      default: true
    },
    hovered: {
      type: Boolean,
      default: false
    }
  },
  components: {
    IconBase,
    LanguageSelector,
    BTooltip
  },
  methods: {
    ...mapActions('Reports', ['setActiveReport']),
    toggleShowChildren (item) {
      item.showChildren = !item.showChildren
    },
    updateSelectedItemName (itemName) {
      this.$emit('updateSelectedItemName', itemName)
    },
    handleRedirection (item) {
      if (item.relative_path) {
        const currentRoute = this.$route.path
        if (currentRoute !== item.link) {
          this.$router.push(item.link)
          if (item.name === 'reports') {
            this.setActiveReport(null)
          }
        }
      } else {
        window.open(item.link, '_self')
      }
      this.updateSelectedItemName(item.name)
    },
    selectMenuItem (item) {
      const hasChildren = item.children.length
      if (hasChildren) {
        this.toggleShowChildren(item)
      } else {
        this.handleRedirection(item)
      }
    },
    isItemSelected (itemName) {
      return this.sidebarListToSelect.includes(itemName)
    }
  },
  computed: {
    showLanguageSelector () {
      return (!this.collapsed && !this.hideLanguageSelector)
    }
  },
  data () {
    return {
      name: 'Sidebar'
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar-list {
  background: var(--sidebar-bg);
  color: $white;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
}

.sidebar-item {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 16px;
  &:hover {
    background: var(--sidebar-hover) !important;
  }
}
.left-border {
  border-left: 3px solid $primary;
  transition: border-left 0.01s ease;
}
.selected {
  background: var(--sidebar-hover) !important;
}
.sidebar-logo {
  width: 20px;
  height: 20px;
  min-width: 20px; /* Width when the icon is empty */
  min-height: 20px; /* Height when the icon is empty */
}

.rotate-down {
  transform: rotate(180deg);
  transition: 0.4s;
}

.rotate-up {
  transform: rotate(360deg);
  transition: 0.4s;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-thumb {
  background: $line;
}

.nested-sidebar-enter-active,
.nested-sidebar-leave-active {
  transition: max-height 1s ease, opacity 1s ease;
}

.nested-sidebar-enter,
.nested-sidebar-leave-to {
  max-height: 0;
  opacity: 0;
}

.nested-sidebar-enter-to,
.nested-sidebar-leave {
  max-height: 500px;
  opacity: 1;
}

.nested-sidebar  {
  .sidebar-item {
    padding-left: 22px;
    background: var(--sidebar-nested);
  }
}

.sidebar-name {
  flex-grow: 1;
  word-wrap: break-word; /* Ensures long words break onto a new line */
  overflow-wrap: break-word; /* Ensures text wraps when necessary */
  white-space: normal; /* Allows wrapping of text */
}

.sidebar-dropdown-arrow {
  width: 14px;
  height: 18px;
  min-width: 14px; /* Width when the icon is empty */
  min-height: 18px; /* Height when the icon is empty */
}
.custom-tooltip {
  width: 225px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  margin-left: 58px !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  overflow: hidden;
  .sidebar-item {
    background: var(--sidebar-nested);
  }
}

.sidebar-name-inside-tooltip {
  min-height: 40px !important;
  background-color: var(--sidebar-hover) !important;
  padding: 10px 16px !important;
  color: $white !important;
  padding: 10px 16px;
}

.custom-tooltip {
  .sidebar-dropdown-arrow, .sidebar-logo {
    display: none;
  }
}

</style>
