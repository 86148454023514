<template>
    <div class="sidebar" :class="{ 'collapsed-sidebar': collapsed }">
        <div class="collapse-button" @click="toggleCollapseStateParent()">
          <div :class="{'collapse-arrow': true, 'collapsed': this.collapsed}">
            <font-awesome-icon icon="chevron-left" size="xs" class="dark-text"/>
          </div>
        </div>
        <sidebar ref="sidebar"
          :sidebarList="nestedSidebarList"
          :collapsed=collapsed
          :sidebarListToSelect = "sidebarListToSelect"
          :nestedSidebar= false
          @updateSelectedItemName="updateSelectedItemName"
        ></sidebar>
    </div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import { isNullOrUndefined } from '../utils/Objects.js'
export default {
  name: 'CollapsableSidebar',
  props: {
    list: Array
  },
  components: {
    Sidebar
  },
  methods: {
    toggleCollapseStateParent () {
      this.collapsed = !this.collapsed
    },
    getAncestorsList (itemName) {
      const item = this.list.find(item => item.name === itemName)
      if (!item) return []

      let currentItem = item
      const sidebarToSelectList = [item.name]
      // Traverse up the parent chain until we reach the root
      while (currentItem && !isNullOrUndefined(currentItem.parentId)) {
        currentItem = this.list.find(i => i.id === currentItem.parentId)
        sidebarToSelectList.push(currentItem.name)
      }

      return sidebarToSelectList
    },
    buildNestedSidebarList (items) {
      let map = {}
      const roots = []

      items.forEach(item => {
        map = { ...map, [item.id]: { ...item, children: [], showChildren: false, isHovered: false } }
      })

      // Expand parent if nested sidebar is selected
      const setParentShowChildren = (parentId) => {
        const parentItem = map[parentId]
        if (parentItem) {
          parentItem.showChildren = true
          if (parentItem.parentId) {
            setParentShowChildren(parentItem.parentId)
          }
        }
      }

      items.forEach(item => {
        if (isNullOrUndefined(item.parentId)) {
          roots.push(map[item.id])
        } else {
          if (map[item.parentId]) {
            if (this.sidebarListToSelect.includes(item.name)) {
              setParentShowChildren(item.parentId)
            }
            map[item.parentId].children.push(map[item.id])
          }
        }
      })
      this.nestedSidebarList = roots
    },
    updateSelectedItemName (itemName) {
      this.sidebarListToSelect = this.getAncestorsList(itemName)
    },
    setSelectedItemBasedOnRoute () {
      const pathname = window.location.pathname
      const item = this.list.filter(item => item.link === pathname)?.[0]
      if (item) {
        this.updateSelectedItemName(item.name)
      }
    }
  },
  mounted () {
    this.setSelectedItemBasedOnRoute()
    this.buildNestedSidebarList(this.list)
  },
  data () {
    return {
      collapsed: false,
      nestedSidebarList: [],
      sidebarListToSelect: []
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  background: var(--sidebar-bg);
  color: $white;
  display: flex;
  flex-direction: column;
  position: -webkit-sticky; /* Safari */
  position: relative;
  white-space: nowrap;
  box-shadow: 1px 0px 1px var(--very-light-shadow);
  max-width: 290px;
  width: 100%;

  &.collapsed-sidebar {
    max-width: 59px; /* Width when collapsed */
    padding-top: 40px;
  }
}

.collapse-button {
  z-index: 100;
  position: absolute;
  width: 28px;
  height: 28px;
  top: 6px;
  background: var(--background-color-primary);
  border-radius: 100%;
  box-shadow: 1px 4px 10px var(--very-light-shadow);
  left: calc(100% - 15px);
}

.collapse-arrow {
  z-index: 100;
  position: absolute;
  left: 38%;
  top: 10%;
}

.collapsed {
  transform: rotate(
    180deg
  );
  transition: 0.3s;
  left: 43%;
  top: 18%;
}
</style>
